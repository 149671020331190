import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import Splide from '@splidejs/splide';
import RCPGallery from './RCPGallery';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

import '@css/app.css';

Alpine.plugin(collapse)

Alpine.data('rcpGallery', RCPGallery);

document.addEventListener('DOMContentLoaded', () => {
  var elms = document.getElementsByClassName( 'splide' );
  for ( var i = 0; i < elms.length; i++ ) {
    const splide = new Splide( elms[ i ], {autoplay: true, type: 'loop', interval: 3000});
    splide.mount();
  }
});

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
    });
});

const swiper = new Swiper('.swiper-container', {
  slidesPerView:'auto',
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
    dragSize: 'auto',
    snapOnRelease: true,
  },
});


const loadMore = document.getElementById('loadmore');
const loader = document.getElementById('loader');
const hid = [...document.querySelectorAll('.award-item.hidden')];
if (loadMore && hid) {

  hid.splice(0, 6).forEach(
    elem => elem.classList.remove('hidden')
  );
  loader.style.display = 'none';

  loadmore.addEventListener('click', function(e) {
    loader.style.display = 'block';
    setTimeout(function(){
      hid.splice(0, 6).forEach(
        elem => elem.classList.remove('hidden')
      )
      loader.style.display = 'none';
      if (hid.length == 0) {
        loadMore.classList.add('hidden');
      }
    }, 1000);
  });
}

Alpine.start()
window.Alpine = Alpine;