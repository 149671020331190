const RCPGallery = () => ({
  imageGalleryOpened: false,
  imageGalleryActiveUrl: null,
  imageGalleryImageIndex: null,

  imageGalleryOpen(event) {
    this.imageGalleryImageIndex = event.target.dataset.index;
    this.imageGalleryActiveUrl = event.target.getAttribute("data-imageFull");
    this.imageGalleryOpened = true;
  },

  imageGalleryClose() {
    this.imageGalleryOpened = false;
    setTimeout(() => this.imageGalleryActiveUrl = null, 300);
  },

  imageGalleryNext(){
    if(this.imageGalleryImageIndex == this.$refs.gallery.childElementCount){
        this.imageGalleryImageIndex = 1;
    } else {
        this.imageGalleryImageIndex = parseInt(this.imageGalleryImageIndex) + 1;
    }
    this.imageGalleryActiveUrl = this.$refs.gallery.querySelector('[data-index=\'' + this.imageGalleryImageIndex + '\']').getAttribute("data-imageFull");
  },

  imageGalleryPrev() {
    if(this.imageGalleryImageIndex == 1){
        this.imageGalleryImageIndex = this.$refs.gallery.childElementCount;
    } else {
        this.imageGalleryImageIndex = parseInt(this.imageGalleryImageIndex) - 1;
    }
    this.imageGalleryActiveUrl = this.$refs.gallery.querySelector('[data-index=\'' + this.imageGalleryImageIndex + '\']').getAttribute("data-imageFull");
  }
});

export default RCPGallery;
